:root, body, html {
  box-sizing: border-box;
}

:root {
  font-family: "Inter Variable", sans-serif;
  font-feature-settings: 'liga' 1, 'calt' 1; /* fix for Chrome */
}
@supports (font-variation-settings: normal) {
  :root { font-family: InterVariable, sans-serif; }
}

body {
  overflow-y: scroll;
  overflow-x: hidden;
  font-family: "Inter Variable";
  /* font-family: Arial, Helvetica, sans-serif; */
}

*,
div, 
h1, 
h2, 
h3, 
h4, 
h5, 
h6, 
header, 
html, 
i, 
img, 
label, 
li, 
nav, 
p, 
small, 
span, 
ul {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

li {
  list-style: none;
}

@-webkit-keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

