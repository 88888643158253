.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 998;
  background-color: rgba(0, 0, 0, 0.75);
}

.modal {
  position: fixed;
  top: 20vh;
  left: 5%;
  width: 90%;
  max-height: 60vh;
  overflow-y: scroll;
  background-color: white;
  padding: 1rem;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 999;
  animation: slide-down 300ms ease-out forwards;
}

.modal::-webkit-scrollbar {
  display: none;
}

@media (min-width: 1024px) {
  .modal {
    width: 50%;
    left: calc(50% / 2); 
  }
}

@keyframes slide-down {
  from {
    transform: translateY(-3rem);
  }
  to {
    transform: translateY(0);
  }
}