.container {
    width: 100%;
    /* border: 1px solid lightgrey; */
}

.title{
    text-align: center;
}
  
.payment-input[type=text], .payment-input[type=tel] {
    width: 100%;
    margin-bottom: 20px;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 3px;
}
  
.payment-label {
    margin-bottom: 10px;
    display: block;
}
  
.icon-container {
    margin-bottom: 20px;
    padding: 7px 0;
    font-size: 24px;
}

.icon-container img {
    height: 30px;
}

.icon-container i, .icon-container img {
    border: 3px solid white;
    margin-right: 5px;
}
  
.btn {
    background-color: #ee9250;
    color: white;
    padding: 12px;
    margin: 10px 0;
    border: none;
    width: 100%;
    border-radius: 3px;
    cursor: pointer;
    font-size: 17px;
}
  
.btn:hover {
    background-color: #fa8128;
}
  
span.price {
    float: right;
    color: grey;
}

p.error {
    color: red;
    margin-top: -15px;
    margin-bottom: 20px;
}
  

@media (max-width: 800px) {
    .container {
        padding: 5px;
    }
}